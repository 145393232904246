body {
	padding: 10px;
}

.titulo {
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
	font-size: x-large;
	font-weight: bold;
}
.izq {
	text-align: left !important;
}

.botones {
	padding-top: 10px;
}

.info {
	padding-top: 10px;
	padding-bottom: 10px;
}

table {
	width: 100%;
}

tr.head{
	background-color: #555555 !important;
	color: white !important;
	font-weight: bold;
}

td {
	padding-top: 0px;
	padding-bottom: 0px;
	text-align: center;
}

td.c { width: 8%; }
td.m { width: 17%; }
td.g { width: 50%; }

tr:nth-child(odd) {
	background-color: #ffffff;
	color: black;
}
tr:nth-child(even) {
	background-color: #bbbbbb;
	color: white;
}

img {
	width: 100%;
	height: 58vh;
	object-fit: contain;
}

.observaciones {
	width: 100%;
}

.mar { padding-left: 10px;  }

.b { font-weight: bold; }
.am { color: #fbb448; }
.ro { color: red; }
.ve { color: #42CF25; }
.az { color: blue; }
.i { font-style: italic; }

.divdatos {
	width: 70%;
	position: relative;
	float: left;
}

.divfotos {
	width: 28%;
	position: relative;
	right: 10px;
	position:  fixed;

}

.mediano {
	width: 49% !important;
}

.flder {
	position: relative;
	float:  right;
}

.flizq {
	position: relative;
	float:  left;
}